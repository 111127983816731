import { Product } from '@/types/products';

const getProductLowerPrice = (product: Product): number => {
  if (product.price.tiers.length === 0) throw new Error('No tiers found');
  const sortedTiers = product.price.tiers.sort(
    (a, b) => a.unitPrice - b.unitPrice,
  );
  if (!sortedTiers[0]) throw new Error('No tiers found');
  return sortedTiers[0].unitPrice;
};

export default getProductLowerPrice;
