'use client';

import { useContext } from 'react';

import { BrandContext } from './context';

// eslint-disable-next-line import/prefer-default-export
export function useBrand() {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrand must be used within a BrandProvider');
  }
  return context;
}
