'use client';

import { useContext } from 'react';
import clsx from 'clsx';

import Button from '@/components/Button';
import ScreenEnterLogger from '@/components/loggers/ScreenEnterLogger';
import Logo from '@/components/Logo';
import { FullscreenContextActions } from '@/context/FullScreenContext';
import { sendLog } from '@/services/logs';
import { useBrand } from '@/utils/brandFeature/useBrand';

import styles from './styles.module.scss';

function FailedView() {
  const { setPopupChildren } = useContext(FullscreenContextActions);
  const { brand } = useBrand();

  return (
    <div className={styles.container}>
      <ScreenEnterLogger name="photosUploadedFailed" />
      <Logo brand={brand} />

      <div>
        <p className={styles.title}>
          Whoops! It looks like you’re in private mode.
        </p>
        <p className={styles.description}>
          Our website needs to remember a little about you to work correctly.
          Please switch off your private browsing and come on back to start
          printing your photos!
        </p>
      </div>
      <div
        className={clsx(styles.buttonsContainer, styles.flexDirectionColumn)}
      >
        <Button
          theme="secondary"
          callback={() => {
            setPopupChildren();
            sendLog('ui_homepage_uploader_go_back_home');
          }}
          width="full"
        >
          <div className={styles.buttonContent}>
            <span>Go back Home</span>
          </div>
        </Button>
      </div>
      {/* This is to fix a scroll bug on mobile on safari */}
      <div className={styles.scrollWhiteDiv} />
    </div>
  );
}

export default FailedView;
