'use client';

import { LottieProps } from 'react-lottie';
import dynamic from 'next/dynamic';

import { LOTTIE_DEFAULT_OPTIONS } from '@/constants';

const Lottie = dynamic(() => import('@/lib/react-lottie'), {
  ssr: false,
});

export interface LottieLazyProps
  extends Pick<
    LottieProps,
    | 'isStopped'
    | 'isClickToPauseDisabled'
    | 'eventListeners'
    | 'width'
    | 'height'
  > {
  options: LottieProps['options'] & {
    path?: string;
  };
  style?: React.CSSProperties;
}

function LottieLazyWrapper({
  options,
  width,
  height,
  style,
  isClickToPauseDisabled,
  isStopped,
  eventListeners,
}: LottieLazyProps) {
  return (
    <Lottie
      options={{
        ...LOTTIE_DEFAULT_OPTIONS,
        ...options,
      }}
      ariaRole="presentation"
      height={height}
      width={width}
      style={style}
      isStopped={isStopped}
      eventListeners={eventListeners}
      isClickToPauseDisabled={isClickToPauseDisabled}
    />
  );
}

export default LottieLazyWrapper;
